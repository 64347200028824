import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import QRCode from "react-qr-code";
import { Link, useNavigate } from "react-router-dom";
import { APIServices } from "../api";
import { ReactComponent as Apikey } from "../components/assets/cloud-dashboard.svg";
import { ReactComponent as CopyFill } from "../components/assets/copy-fill.svg";
import { ReactComponent as Refresh } from "../components/assets/refresh.svg";
import { ReactComponent as Requests } from "../components/assets/sqaure-dashboard.svg";
import { ReactComponent as Wallet } from "../components/assets/wallet-dashboard.svg";
import MainLayout from "../components/layout/mainLayout";
import Modal from "../components/shared/modal";
import { addressHandler } from "../utils/addressHandler";
import LargeNumFormatter from "../utils/numberFormatter";
import snack from "../utils/snack";
import { commafy2 } from "../utils/commafy";
import { ReactComponent as Nocontent } from "../components/assets/nocontent.svg";
import moment from "moment";
import moment2 from "jalali-moment";
import PaginationComponent from "../components/shared/pagination";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AiOutlineLoading } from "react-icons/ai";

export default function Dashboard() {
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [qrModal, setQrModal] = useState(false);
  const [remain, setRemain] = useState(0);
  const [checking, setChecking] = useState(false);
  const navigate = useNavigate();
  const [sumChart, setSumChart] = useState([]);
  const [amountCahrt, setamountCahrt] = useState([]);
  const [count, setCount] = useState(0);
  const [pageDeposit, setPageDeposit] = useState(1);
  // const [keyList, setKeyList] = useState([]);
  const [depositList, setDepositList] = useState([]);
  const [last30DaysRequests, setLast30DaysRequests] = useState(0);
  const [priceAndCountList, setPriceAndCountList] = useState([]);
  const [countRequest, setCountRequest] = useState(0);
  const [loadingGetPrice, setLoadingGetPrice] = useState(false);
  const [howManyDays, setHowManyDays] = useState(30);

  const [loadingFilter, setLoadingFilter] = useState(false);
  const [timeRange, setTimeRange] = useState({
    from: "",
    to: "",
  });

  const getData = () => {
    APIServices.getInformationsUser().then((res) => {
      // console.log(res.data.data);
      setUserInfo(res.data.data);
    });

    APIServices.getAllUserRequestsCount().then((res) => {
      setLast30DaysRequests(res.data.count);
    });
  };

  const getDataForChart = (count) => {
    APIServices.getRemainRequestCount({ count: count }).then((res) => {
      setRemain(res.data.count);
      let temp1 = [];
      let temp2 = [];
      res.data.data?.map((item) => {
        temp1.push({
          x: moment(item.createddate).format("YYYY-MM-DD"),
          y: Number(item.count),
        });
        temp2.push({
          x: moment(item.createddate).format("YYYY-MM-DD"),
          y: item.sum,
        });
      });
      setSumChart(temp2);
      setamountCahrt(temp1);
      setChartBAr({ ...chartBar, series: [{ name: "", data: temp1 }] });
    });
  };

  const getdepositsListData = (e) => {
    setLoadingFilter(true);
    APIServices.getdepositsList({
      page: e,
      pageSize: 10,
      apikey: userInfo.apikeyvalue,
      ...(timeRange.from && { from: timeRange.from }),
      ...(timeRange.to && { to: timeRange.to }),
    })
      .then((res) => {
        setDepositList(res.data.data);
        setCount(Math.ceil(res.data.totalCount / 10));
      })
      .finally((e) => {
        setLoadingFilter(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getDataForChart(howManyDays);
  }, [howManyDays]);

  useEffect(() => {
    if (userInfo.apikeyvalue) {
      getdepositsListData(1);
    }
  }, [userInfo]);

  const handleClickApikey = () => {
    navigate("/apikey");
  };

  const handleClickCreateWallet = () => {
    setLoading(true);
    APIServices.createWalletUser()
      .then((res) => {
        snack.success("Wallet generated successfully.");
        getData();
      })
      .finally((e) => {
        setLoading(false);
      });
  };

  const handleRefresh = () => {
    setChecking(true);
    APIServices.handleCheckBalanceUser(userInfo?.walletaddress)
      .then((res) => {
        if (res.data.success) {
          snack.success(res.data.message);
        }
        getData();
      })
      .finally((e) => {
        setChecking(false);
      });
  };

  const [chartBar, setChartBAr] = useState({
    series: [
      {
        name: "",
        data: sumChart,
      },
    ],
    options: {
      chart: {
        height: 450,
        type: "area",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      grid: {
        borderColor: "none",
        strokeDashArray: 0,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      stroke: {
        // curve: 'straight',
        width: 2,
      },
      colors: ["#1652F0"],
      xaxis: {
        // categories: allCategories,
        labels: {
          show: true,
          style: {
            colors: [
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
            ],
          },
        },
        axisBorder: {
          show: true,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          show: true,
          formatter: function (value) {
            // if(value < 1000) {
            //   return parseInt(value) === value ? value : ''
            // } else {
            return Number.isInteger(value) ? LargeNumFormatter(value, 5) : "";
            // }
          },
          style: {
            colors: [
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
              "#9E9FAA",
            ],
          },
        },
        axisBorder: {
          show: false,
        },
        crosshairs: {
          show: true,
          position: "back",
          stroke: {
            color: "#b6b6b6",
            width: 1,
            dashArray: 0,
          },
        },
        // stepSize: 2,
      },
      tooltip: {
        x: {
          // format: "dd/MM/yy HH:mm",
        },
      },
    },
  });

  const estimateTransferPrice = () => {
    setLoadingGetPrice(true);
    APIServices.handleEstimatePrice()
      .then((res) => {
        setPriceAndCountList(res.data.data);
        let tempCount = 0;
        res.data.data
          ?.sort((a, b) => Number(b.save32k) - Number(a.save32k))
          .map((item) => {
            tempCount += item.amount32k;
          });
        setCountRequest(tempCount);
      })
      .finally((e) => {
        setLoadingGetPrice(false);
      });
  };

  const handleChangeDateRange = (e, what) => {
    setTimeRange({
      ...timeRange,
      [what]: moment2(e.$d).format("YYYY-MM-DD"),
    });
  };

  useEffect(() => {
    estimateTransferPrice();
    setInterval(() => {
      estimateTransferPrice();
    }, 30000);
  }, []);

  const handleChangeTime = (e) => {
    console.log(e);
    setHowManyDays(e);
  };

  return (
    <MainLayout>
      <Modal
        onClose={() => {
          setQrModal(false);
        }}
        open={qrModal}
        title={"Scan QR to deposit"}
      >
        <div className="w-full p-4 bg-white flex justify-center">
          <QRCode value={userInfo?.walletaddress} size={178} />
        </div>
        <div className="flex justify-between my-3 items-center">
          <span className="text-black mr-1">{userInfo?.walletaddress}</span>
          {userInfo?.walletaddress && (
            <CopyFill
              className="cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(userInfo.walletaddress);
                snack.success("Copied.");
              }}
            />
          )}
        </div>
        <div className="flex justify-center">
          <button
            className="link-auth-register"
            onClick={() => {
              setQrModal(false);
            }}
          >
            Ok
          </button>
        </div>
      </Modal>
      <div className="text-2xl mb-2 ml-1">Dashboard</div>
      <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
        <div className="col-span-1 shadow-sm card">
          <div className="flex gap-4 items-center">
            <div className="w-16 h-16">
              <Requests className="" />
            </div>
            <div className="flex flex-col justify-between">
              <span className="text-base relative top-2 text-[#9E9FAA]">
                Last 30 Days Requests
              </span>
              <span className="text-[32px] relative top-0.5">
                {commafy2(Number(last30DaysRequests)) ?? "--"}
              </span>
            </div>
          </div>
          <div className="flex text-sm my-2 justify-between items-center border-t border-[#9E9FAA] pt-4 relative top-4">
            <div className="text-[#9E9FAA] text-sm">Remain Request </div>
            <span className="text-black mr-1 flex items-center gap-2">
              {commafy2(remain)}
              <span className="text-[#9E9FAA] text-sm">Request(s)</span>
            </span>
          </div>
        </div>
        <div className="col-span-1 shadow-sm card">
          {userInfo?.apikeyvalue ? (
            <div className="flex gap-4 items-center">
              <div className="w-16 h-16">
                <Apikey className="" />
              </div>
              <div className="flex flex-col justify-between">
                <span className="text-base relative text-[#9E9FAA]">
                  Default Api key
                </span>
                <div className="flex text-sm gap-2">
                  <span className="relative">
                    {addressHandler(userInfo?.apikeyvalue) ?? "--"}
                  </span>
                  <CopyFill
                    className="cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(userInfo.apikeyvalue);
                      snack.success("Copied.");
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <div className="flex gap-4 items-center">
                <div className="w-16 h-16">
                  <Apikey className="" />
                </div>
                <div className="flex items-center gap-4 flex-col">
                  <span className="text-base relative top-2 text-[#9E9FAA]">
                    You don't have API Key
                  </span>
                </div>
              </div>
              <div className="text-xs font-light gap-3 min-h-10 flex justify-end">
                <button
                  onClick={handleClickApikey}
                  className="inline-block w-[209px] relative top-1 disabled:cursor-not-allowed disabled:bg-[#D0D0D0] h-10 text-center rounded-[5px] text-balance text-white bg-black"
                >
                  Create New API Key
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="col-span-1 shadow-sm card">
          {userInfo?.walletaddress ? (
            <>
              <div className="flex justify-between">
                <div className="w-full">
                  <div className="flex gap-4 items-center">
                    <div className="w-16 h-16">
                      <Wallet className="" />
                    </div>
                    <div className="flex flex-col justify-between">
                      <span className="text-base relative text-[#9E9FAA]">
                        Balance
                      </span>
                      <div className="flex text-sm gap-2">
                        <span className="relative text-[32px]">
                          {commafy2(userInfo?.balance?.toFixed(2))}
                        </span>
                        <span className="text-base relative text-[#000000]">
                          TRX
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-8 h-8">
                  <Refresh
                    className={`cursor-pointer ${
                      checking ? " animate-spin" : ""
                    }`}
                    onClick={() => {
                      !checking && handleRefresh();
                    }}
                  />
                </div>
              </div>
              <div className="text-xs font-light gap-3 min-h-10 flex justify-end">
                <button
                  onClick={() => {
                    setQrModal(true);
                  }}
                  className="inline-block w-[123px] relative top-1 disabled:cursor-not-allowed disabled:bg-[#D0D0D0] h-10 text-center rounded-[5px] text-balance text-white bg-black"
                >
                  Deposit
                </button>
              </div>
            </>
          ) : (
            <div className="flex flex-col gap-4">
              <div className="flex gap-4 items-center">
                <div className="w-16 h-16">
                  <Wallet className="" />
                </div>
                <div className="flex items-center gap-4 flex-col">
                  <span className="text-base relative top-2 text-[#9E9FAA]">
                    You don't have Wallet
                  </span>
                </div>
              </div>
              <div className="text-xs font-light gap-3 min-h-10 flex justify-end">
                <button
                  onClick={handleClickCreateWallet}
                  disabled={loading}
                  className="inline-block w-[209px] relative top-1 disabled:cursor-not-allowed disabled:bg-[#D0D0D0] h-10 text-center rounded-[5px] text-balance text-white bg-black"
                >
                  Create New Wallet
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="p-8 rounded-[20px] bg-white shadow-sm my-4">
        <div>Live Price (your current plan)</div>
        <div className=" overflow-x-auto">
          <table>
            <thead>
              <tr className="h-10 rounded-sm">
                <th className="px-3 whitespace-nowrap">Has USDT price</th>
                <th className="px-3 whitespace-nowrap">
                  Count of availabe 32k
                </th>
                <th className="px-3 whitespace-nowrap">Saved cost for 32k</th>
                <th className="px-3 whitespace-nowrap">
                  Does not have USDT price
                </th>
                <th className="px-3 whitespace-nowrap">
                  Count of availabe 65k
                </th>
                <th className="px-3 whitespace-nowrap">Saved cost for 65k</th>
              </tr>
            </thead>
            <tbody>
              {loadingGetPrice
                ? priceAndCountList.length
                  ? [...Array(priceAndCountList.length)]
                      .map((i, ii) => ii)
                      ?.map((item, index) => (
                        <tr key={index}>
                          <td colSpan={6}>
                            <span className="inline-block w-full h-9 rounded-sm animate-pulse bg-gray-300"></span>
                          </td>
                        </tr>
                      ))
                  : [0, 1, 2, 3, 4, 5]?.map((item, index) => (
                      <tr key={index}>
                        <td colSpan={6}>
                          <span className="inline-block w-full h-9 rounded-sm animate-pulse bg-gray-300"></span>
                        </td>
                      </tr>
                    ))
                : priceAndCountList
                    ?.sort((a, b) => Number(b.save32k) - Number(a.save32k))
                    .map((item, index) => (
                      <tr className="h-10">
                        <td className="text-center">{item.price32k} TRX</td>
                        <td className="text-center">{item.amount32k}</td>
                        <td className="text-center">{item.save32k} %</td>
                        <td className="text-center">{item.price65k} TRX</td>
                        <td className="text-center">{item.amount65k}</td>
                        <td className="text-center">{item.save65k} %</td>
                      </tr>
                    ))}
            </tbody>
          </table>
        </div>
        <div>availabe for now : {countRequest}</div>
      </div>
      <div className="p-8 rounded-[20px] bg-white shadow-sm my-4">
        <div className="flex justify-between items-center">
          <div className="flex gap-4 justify-between">
            <div className="flex gap-1 items-center">
              <span
                className={`inline-block w-2.5 h-2.5 rounded-full bg-[#2083FF] opacity-100`}
              ></span>
              <div
                className={`text-black`}
                // onClick={() => handleSwitch(1)}
              >
                Requests
              </div>
            </div>
          </div>
          <div>
            <div className="flex gap-1 items-center">
              <span></span>
              <div
                className={`cursor-pointer`}
                // onClick={() => handleSwitch(2)}
              >
                <select
                  className="focus:outline-none border border-gray-400 rounded-md px-2 py-1"
                  defaultValue={howManyDays}
                  onChange={(e) => {
                    handleChangeTime(Number(e.target.value));
                  }}
                >
                  <option selected={howManyDays == "1"} value="1">
                    Today
                  </option>
                  <option selected={howManyDays == "3"} value="3">
                    3 Days
                  </option>
                  <option selected={howManyDays == "7"} value="7">
                    7 Days
                  </option>
                  <option selected={howManyDays == "14"} value="14">
                    14 Days
                  </option>
                  <option selected={howManyDays == "30"} value="30">
                    30 Days
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="min-h-[400px]">
          {amountCahrt.length > 0 ? (
            <ReactApexChart
              options={chartBar.options}
              series={chartBar.series}
              type="area"
              height={450}
            />
          ) : (
            <div className="min-h-[400px] w-full flex justify-center items-center text-[#9E9FAA]">
              <span>You don't have any request</span>
            </div>
          )}
        </div>
      </div>
      <div className="p-8 rounded-[20px] bg-white shadow-sm my-4 ">
        <div>Deposits</div>
        <div className="my-4 flex flex-wrap gap-4">
          <div className="flex gap-1 min-w-44 whitespace-nowrap items-center">
            <label className="text-sm">From:</label>
            <label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disableFuture
                  className="datetimepicker"
                  // maxDate={timeRange.to ?? null}
                  format="YYYY-MM-DD"
                  onChange={(e) => handleChangeDateRange(e, "from")}
                />
              </LocalizationProvider>
            </label>
          </div>
          <div className="flex gap-1 min-w-44 whitespace-nowrap items-center">
            <label className="text-sm">To:</label>
            <label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disableFuture
                  format="YYYY-MM-DD"
                  // minDate={timeRange.from ?? null}
                  className="datetimepicker"
                  onChange={(e) => handleChangeDateRange(e, "to")}
                />
              </LocalizationProvider>
            </label>
          </div>
          {loadingFilter ? (
            <button className="h-10 w-[110px] text-center text-white bg-black rounded-md flex justify-center items-center">
              <AiOutlineLoading className="animate-spin" />
            </button>
          ) : (
            <button
              onClick={() => {
                getdepositsListData(pageDeposit);
              }}
              className="h-10 w-[110px] text-center text-white bg-black rounded-md"
            >
              Filter
            </button>
          )}
        </div>
        <div className="overflow-x-auto">
          <table>
            <thead className="h-10 bg-gray-200 rounded-sm">
              <tr className="h-full">
                <th className="min-h-20 px-3">#</th>
                <th className="min-h-20 px-3">From</th>
                {/* <th className="min-h-20 px-3">To Address</th> */}
                <th className="min-h-20 px-3">Amount</th>
                <th className="min-h-20 px-3">Time</th>
                <th className="min-h-20 px-3">TxId</th>
                {/* <th className="min-h-20 px-3">Amount</th> */}
              </tr>
            </thead>
            <tbody>
              {depositList?.map((item, index) => (
                <tr className="h-10" key={item?.txId}>
                  <td className="text-center text-sm px-2 whitespace-nowrap">
                    {(pageDeposit - 1) * 10 + index + 1}
                  </td>
                  <td className="text-center text-sm px-2 whitespace-nowrap flex justify-center">
                    <div className="flex gap-1 justify-between items-center w-40">
                      <span>{addressHandler(item.from)}</span>
                      <div className="flex gap-1 items-center">
                        <CopyFill
                          className="cursor-pointer"
                          onClick={() => {
                            navigator.clipboard.writeText(item.from);
                            snack.success("Copied.");
                          }}
                        />
                        <Link
                          target="_blank"
                          to={`https://tronscan.org/#/address/${item.from}`}
                        >
                          <InfoRoundedIcon fontSize="small" />
                        </Link>
                      </div>
                    </div>
                    {/* {item.from} */}
                  </td>
                  <td className="text-center text-sm px-2 whitespace-nowrap">
                    {item.amount}
                  </td>
                  <td className="text-center text-sm px-2 whitespace-nowrap">
                    {moment2(item?.date).format("jYYYY/jMM/jDD HH:mm:ss")}
                  </td>
                  <td className="text-center text-sm px-2 whitespace-nowrap flex justify-center">
                    <div className="flex gap-1 justify-between items-center w-40">
                      <span>{addressHandler(item.txId)}</span>
                      <div className="flex gap-1 items-center">
                        <CopyFill
                          className="cursor-pointer"
                          onClick={() => {
                            navigator.clipboard.writeText(item.txId);
                            snack.success("Copied.");
                          }}
                        />
                        <Link
                          target="_blank"
                          to={`https://tronscan.org/#/transaction/${item.txId}`}
                        >
                          <InfoRoundedIcon fontSize="small" />
                        </Link>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {!depositList.length && (
          <div className="flex justify-center items-center min-h-[420px] flex-col">
            <Nocontent />
            <div className="text-center">No data found!</div>
          </div>
        )}
        <div className="mt-6">
          {count > 1 && (
            <PaginationComponent
              count={count}
              page={pageDeposit}
              onChange={(e) => {
                setPageDeposit(e);
                getdepositsListData(e);
              }}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
}

const useStyles = makeStyles(() => ({
  formControl: {
    "*": {
      fontFamily: "lufga",
    },
    "& .MuiMenu-padding": {
      padding: "0!important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0!important",
    },
    "& .MuiInputBase-root": {
      color: "#9E9FAA",
      minWidth: "120px",
      justifyContent: "center",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingRight: "0px",
    },
  },
  select: {
    width: "auto",
    fontSize: "12px",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  selectIcon: {
    position: "relative",
    color: "#9E9FAA",
    fontSize: "14px",
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      color: "#000000",
      fontSize: "12px",
    },
    "& li.Mui-selected": {
      color: "white",
      background: "#000000",
    },
    "& li.Mui-selected:hover": {
      background: "#000000",
    },
    "& li:hover": {
      background: "#000000",
      color: "#ffffff",
    },
  },
}));

const DropDown = ({ value, handleChange, items }) => {
  const classes = useStyles();

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    getContentAnchorEl: null,
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={value}
        onChange={handleChange}
        // disableUnderline
        IconComponent={KeyboardArrowDownRoundedIcon}
        MenuProps={menuProps}
        classes={{
          select: classes.select,
          icon: classes.selectIcon,
        }}
      >
        {items.map((item) => (
          <MenuItem key={item.key} value={item.value}>
            {item.key}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
